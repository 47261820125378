var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBLPLANT",
                      value: _vm.equipData.plantName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001720",
                      value: _vm.equipData.equipmentTypeName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001727",
                      value: _vm.equipData.equipmentNo,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "LBL0001725",
                      value: _vm.equipData.equipmentName,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-table", {
          ref: "table1",
          attrs: {
            title: "LBL0003601",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            pagePerRow: { pageNumber: 5 },
            gridHeight: "300px",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["checkStatusCd"]
                    ? [
                        props.row.checkStatusCd === "MCSC000020"
                          ? _c(
                              "q-chip",
                              {
                                staticClass:
                                  "full-size-chip maintenanceStatusCd-blinking",
                                attrs: {
                                  color: _vm.setTagColor(
                                    props.row.checkStatusCd
                                  ),
                                  outline: "",
                                  square: "",
                                  "text-color": "white",
                                  icon: "alarm",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setTagName(props.row.checkStatusCd)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "q-chip",
                              {
                                staticClass: "full-size-chip",
                                attrs: {
                                  color: _vm.setTagColor(
                                    props.row.checkStatusCd
                                  ),
                                  outline: "",
                                  square: "",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setTagName(props.row.checkStatusCd)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _c("c-table", {
          ref: "table2",
          attrs: {
            title: "LBL0003602",
            columns: _vm.grid2.columns,
            data: _vm.grid2.data,
            pagePerRow: { pageNumber: 5 },
            gridHeight: "300px",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["maintenanceStatusCd"]
                    ? [
                        props.row.maintenanceStatusCd === "MCSC000020"
                          ? _c(
                              "q-chip",
                              {
                                staticClass:
                                  "full-size-chip maintenanceStatusCd-blinking",
                                attrs: {
                                  color: _vm.setTagColor(
                                    props.row.maintenanceStatusCd
                                  ),
                                  outline: "",
                                  square: "",
                                  "text-color": "white",
                                  icon: "alarm",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setTagName(
                                        props.row.maintenanceStatusCd
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "q-chip",
                              {
                                staticClass: "full-size-chip",
                                attrs: {
                                  color: _vm.setTagColor(
                                    props.row.maintenanceStatusCd
                                  ),
                                  outline: "",
                                  square: "",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.setTagName(
                                        props.row.maintenanceStatusCd
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        }),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }